<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    class="brand-logo-dark"
    y="0px"
    viewBox="0 0 1000 1000"
    style="enable-background:new 0 0 1000 1000;"
    xml:space="preserve"
  >
    <g>
      <polygon points="603.37,204.51 603.37,349.57 753.79,500 603.37,650.43 603.37,795.49 898.85,500" />
      <polygon points="601.15,854.94 246.21,500 601.15,145.06 601.15,0 101.15,500 601.15,1000" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'KingpinLogo',
}
</script>
